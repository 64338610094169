import React from 'react';
import logo from '../../assets/images/logo.svg';
import bars from '../../assets/images/icons8-menu.svg';
import './index.css';


const Navbar = () => {
  function handleClose() {
    const el = document.getElementById("responsive-menu-items");
    el.style.display = 'none';
  }
  function handleOpen() {
    const el = document.getElementById("responsive-menu-items");
    el.style.display = 'flex';
  }

  return (
    <div className="navbar-container flex-row" id="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="BSBroker" />
      </div>
      <div className="navbar-menu-items" id="responsive-menu-items">
        <div id="close-button" onClick={handleClose}><span>X</span></div>
        <a href="#what-we-do">O que fazemos</a>
        <a href="#our-services">Nossos serviços</a>
        <a href="#use-cases">Casos de uso</a>
        <a href="#documentation">Documentação</a>
        <a href="#contact-us">Fale conosco</a>
      </div>
      <div className="icon" onClick={handleOpen}>
        <img src={bars} alt="menu-bars" width="35" />
      </div>
    </div>
  )
}

export default Navbar;
