import React from 'react';
import Navbar from '../../Navbar';
import introAnimations from '../../../assets/images/conections.svg';
import Button from '../../Button';
import './index.css';


const Intro = () => {
  const handleClick = () => {
    alert('Oops!! Ainda trabalhando nisso');
  }

  return (
    <section className="flex-column intro-section" id="intro" >
      <Navbar />
      <div className="intro-content flex-row">
        <div className="intro-text">
          <p>
            <span>Simplificando a integração </span>
            de seus aplicativos com a sua conta bancária de forma segura.
          </p>
          <Button title="Veja nossa documentação" handleClick={handleClick} />
        </div>
        <div className="intro-animations">
          <img src={introAnimations} alt="intro-animations" width="400" />
        </div>
      </div>
    </section>
  )
}

export default Intro;