import yourClients from '../../../../assets/images/bottom-section-img1.svg'
import companies from '../../../../assets/images/bottom-section-img2.svg'
import financialCompanies from '../../../../assets/images/bottom-section-img3.svg'


export const cardData = [
  {
    id: 1,
    title: 'Seus clientes',
    img: yourClients,
    alt: 'your-clients',
    description: 'Permitimos que os clientes das Instituições financeiras utilizem os serviços e credibilidade de seus Bancos para acessarem aplicativos e serviços no mundo digital seguindo o padrão do Openbanking brasileiro.'
  },
  {
    id: 2,
    title: 'Empresas',
    img: companies,
    alt: 'companies',
    description: 'Facilitamos a conexão a diversos Bancos sendo um único ponto de conexão de forma segura e simples.'
  },
  {
    id: 3,
    title: 'Instituições Financeiras',
    img: financialCompanies,
    alt: 'financial-companies',
    description: 'Conectamos diversos parceiros, garantindo a segurança de acesso e promovemos seus produtos de uma forma simples e com suporte e aceleração no desenvolvimento.'
  },
]