import b2b from '.././../../assets/images/b2b.svg';
import b2c from '.././../../assets/images/b2c.svg';
import specialCases from '.././../../assets/images/special-cases.svg';

export const cardsData = [
  {
    img: b2c,
    title: 'B2C',
    description: 'Empresas e Fintechs podem consumir o OpenBanking de forma prática, como consumir serviços e aplicativos utilizando o ‘login’ do seu Banco, ou, utilizar seu programa de milhas para consumir serviços do dia a dia.',
  },
  {
    img: b2b,
    title: 'B2B',
    description: 'Empresas consomem os serviços financeiros dos bancos para prover outros serviços, como, por exemplo fornecer crédito para atividades de consumo ao dia a dia, seguros para aluguel de carros compartilhados e uso do DDA.',
  },
  {
    img: specialCases,
    title: 'Cenários especializados',
    description: 'Converse conosco, podemos te ajudar a habilitar estratégias financeiras em seus canais digitais.',
  },
]
